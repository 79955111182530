import { Button } from '@mui/material'
import axios from 'axios';
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import env from "../../services/ApiServices";

export default function Logout() {
    const navigate = useNavigate();

    const handleLogout = () => {
        axios.post(env.BASE_URL + "/signout",{},{withCredentials: true})
            .then(response => {
                console.log(response)
                
            });
        navigate("/login")
    }

    return (
        <div>
            <h2 style={{ textAlign: "center" }}> Are you sure you want to Signout? </h2>
            <div style={{
                margin: 0,
                position: "absolute",
                top: "20%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}> <Button variant='contained' onClick={handleLogout} > Signout </Button> </div>
        </div>
    )
}
