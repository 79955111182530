import React from 'react'
import { ErrorMessage, Field } from 'formik'

import TextError from './TextError'

function FormikCheckBox(props) {
    const { label, name, options, ...rest } = props
    return (
        <div className='form-control'>
            <label htmlFor={name}>{label}</label>
            <Field id={name} name={name}>
                {
                   ({ field }) => {return (
                        <input
                            type='checkbox'
                            id={name}
                            {...field}
                            {...rest}
                            checked={field.value}
                        />
                    
                  )}
                }
            </Field>
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default FormikCheckBox