import React, { useEffect, useState } from 'react'

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../navigationBar/NavBar';
import env from "../../services/ApiServices";

function createData(name, usedLeaves, allLeaves) {
    return { name, usedLeaves, allLeaves };
}

const tempRows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    width: '75%',
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: 'red',
    },
}));

function UserLeave() {
    const [rows, setRows] = useState([])
    const [currentYear, setCurrentYear] = useState(0);
    const [maxLeave, setMaxLeave] = useState(0);
    const [usedLeave, setUsedLeave] = useState(0);
    const [openExpandLeaveView, setOpenExpandLeaveView] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [logData, setLogDate] = useState([]);
    const [currentUser, setCurrentUser] = React.useState([]);


    React.useEffect(() => {
        axios.get(env.BASE_URL +"/user",{withCredentials: true})
            .then(response => {
                setCurrentUser(response.data);
            })
    }, [])

    const { userId } = useParams()

    useEffect(() => {
        var dt = new Date();
        if (userId == null) {
            axios.get(env.BASE_URL + "/leaves/user",{withCredentials: true})
                .then(response => {
                    setRows([...response.data].sort((a, b) => b.year - a.year));
                })
            axios.get(env.BASE_URL + "/leaves/user/current",{withCredentials: true})
                .then(response => {
                    console.log(response)
                    setCurrentYear(response.data.year)
                    setMaxLeave(response.data.totalLeaves)
                    setUsedLeave(response.data.usedLeaves)
                })
        }
        else {
            axios.get(env.BASE_URL + "/leaves/user/" + userId,{withCredentials: true})
                .then(response => {
                    setRows([...response.data].sort((a, b) => b.year - a.year));
                })
        }
    }, [currentUser, userId])

    const handleRowClick = (row) => {
        setOpenExpandLeaveView(true);
        setSelectedRow(row);
        axios.get(env.BASE_URL + "/leaves/year/" + row.year,{withCredentials: true})
            .then(response => {
                console.log(response)
                setLogDate(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleCloseExpandLeaveView = () => {
        setOpenExpandLeaveView(false);
    }

    return (
        <>
            <NavBar currentUser={currentUser} />
            <Grid container xs={12} sx={{
                //  backgroundColor : 'red'
                margin: '20px',
            }} justifyContent='center'>
                <Grid xs={12} container justifyContent='center' >
                    <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                        LEAVE HISTORY SUMMARY
                    </Typography>
                </Grid>

                <Grid xs={12} container justifyContent='center' >
                    <Typography variant='h6' style={{ marginTop: 50 }}>
                        Last Reset Date {currentYear}
                    </Typography>
                </Grid>

                <Grid container intem xs={12} justifyContent='center' style={{ marginTop: 50 }}>
                    <Tooltip title={"Used Leaves" + " " + usedLeave + "/" + maxLeave} >
                        <BorderLinearProgress variant="determinate"
                            sx={{ backgroundColor: 'green' }}
                            value={(usedLeave / maxLeave) * 100}
                        />
                    </Tooltip>
                </Grid>

                <Grid xs={12} container justifyContent='center' style={{ marginTop: 50 }} >
                    <Typography variant='h6'>
                        ALL LEAVE HISTORY
                    </Typography>
                </Grid>

                <Grid container intem xs={5} style={{ marginTop: 50 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 10 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Year</TableCell>
                                    <TableCell align="center">Leave hours</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.year}
                                        onClick={() => handleRowClick(row)}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" align="center">
                                            {row.year}
                                        </TableCell>
                                        <TableCell align="center">{row.usedLeaves}</TableCell>
                                        {/* <TableCell align="right">{row.allLeaves}</TableCell> */}
                                        {/* <TableCell align="right">{row.carbs}</TableCell>
                                  <TableCell align="right">{row.protein}</TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Dialog
                    open={openExpandLeaveView}
                    onClose={handleCloseExpandLeaveView}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
                        Leaves Taken in the year {selectedRow.year}
                    </DialogTitle>

                    <DialogContent>
                        <Grid container xs={12} justifyContent='center' alignItems='center' p={2}>
                            <Table sx={{ minWidth: 10 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">Leave hours</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logData.map((row) => (
                                        <TableRow
                                            key={row.year}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="center">
                                                {row.date}
                                            </TableCell>
                                            <TableCell align="center">{row.leaveHours}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleCloseExpandLeaveView}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </>
    )
}

export default UserLeave