import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import styles from './headerStyleNew';
import { makeStyles } from '@mui/styles';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Select, Slide, Table, TableBody, TableCell, TableHead, TableRow, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DescriptionModified from './DescriptionModified';
import ProjectTableToolBar from './ProjectTableToolBar';
import CloseIcon from '@mui/icons-material/Close';
import NavBar from '../navigationBar/NavBar';
import env from "../../services/ApiServices";

const useStyles = makeStyles(theme => styles(theme));

var once = 0;
var windowWidth = window.innerWidth;

export default function Projecttable() {

  const { userId, weekId } = useParams();
  const navigate = useNavigate();
  const [userSelectedWeek, setUserSelectedWeek] = React.useState(getWeekNumber(new Date()))
  const [userSelectedDate, setUserSelectedDate] = React.useState(new Date());
  const [changeUserComments, setChangeUserComments] = React.useState(false);
  /**
   * Hooks getting data from the backend 
   */
  const [timeSheet, setTimeSheet] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [allItemLists, setAllItemLists] = React.useState([]);

  /**
   * Hooks storing the item lists which are avaiable and which are already used 
   */
  const [availableItemList, setAvaiableItemList] = React.useState([]);
  const [allLocalItemList, setAllLocalItemList] = React.useState([]);


  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [newLogHours, setLogHours] = React.useState(0);
  const [update, setUpdate] = React.useState();
  const [editSheet, setEditSheet] = React.useState(true);
  const [itemListSelected, setItemListSelected] = React.useState();
  const [submitButtons, setSubmitButtons] = React.useState(false);
  const [itemListHandle, setItemListHandle] = React.useState(false);
  const [submitTimeSheet, setSubmitTimeSheet] = React.useState(false);
  const [newItemListValue, setNewItemListValue] = React.useState("");
  const [timeSheetStatus, setTimeSheetStatus] = React.useState([]);

  /**
   * Hooks storing the view control of the dialogs when creating and 
   * deleting a item list
   */
  const [openCreateItem, setOpenCreateItem] = React.useState(false);
  const [openDeleteItem, setOpenDeleteItem] = React.useState(false);
  const [openApproveDialog, setOpenApproveDialog] = React.useState(false);
  const [openRejectDialog, setOpenRejectDialog] = React.useState(false);
  const [openCreateNewItemListItem, setOpenCreateNewItemListItem] = React.useState(false);
  const [openDeleteItemListFromDatabase, setOpenDeleteItemListFromDatabase] = React.useState(false);
  const [openStatusDialogBox, setOpenStatusDialogBox] = React.useState(false);
  const [rejectTimeSheetMessage, setRejectTimeSheetMessage] = React.useState("");
  const [statusViewButton, setStatusViewButton] = React.useState(true);
  const [disableSubmitButton, setDisableSubmitButton] = React.useState(false);
  const [timeSheetStatusText, setTimeSheetStatusText] = React.useState("");
  const [timeSheetWeekDays, setTimeSheetWeekDays] = React.useState([]);
  const [currentDayField, setCurrentDayField] = React.useState("");
  const [currentUser, setCurrentUser] = React.useState([]);

  React.useEffect(() => {
    axios.get(env.BASE_URL + "/user",{withCredentials: true})
      .then(response => {
        setCurrentUser(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  React.useEffect(() => {
    console.log("GETTING TIME SHEET")
    if (userId == null && weekId == null) {
      console.log("params")
      axios.get(env.BASE_URL + "/timesheet/week/" + userSelectedWeek[0] + userSelectedWeek[1],{withCredentials: true})
        .then(response => {
          if (response.status == 204) {
            createTimeSheet(userSelectedDate, userSelectedWeek);
          }
          else {
            setTimeSheet(response)
          }
        })
        .catch(function (error) {
          console.log(error)
        });
    }
    else {
      axios.get(env.BASE_URL + "/timesheet/user/" + userId + "/week/" + weekId,{withCredentials: true})
        .then(response => {
          console.log(response)
          if (response.status == 204) {
            createTimeSheet(userSelectedDate, userSelectedWeek);
          }
          else {
            setTimeSheet(response)
          }
        })
        .catch(function (error) {
          once += 1;
          if (once == 1) {
            setStatusViewButton(false);
            alert("TimeSheet does not exist");
          }
        });
    }
  }, [newLogHours, userSelectedWeek, userSelectedDate, currentUser, update, changeUserComments])

  React.useEffect(() => {
    console.log("updating time sheet data")
    if (timeSheet.data) {
      udapteTimeSheetWeekDays();
      if (timeSheet.data.approved) {
        setTimeSheetStatusText("Approved");
        setDisableSubmitButton(true);
      }
      else if (timeSheet.data.submitted) {
        setTimeSheetStatusText("Submitted");
        setDisableSubmitButton(true);
      }
      else if (timeSheet.data.rejected) {
        setTimeSheetStatusText("Rejected");
        setDisableSubmitButton(false);
      }
      else {
        setTimeSheetStatusText("");
      }


      if (timeSheet.data.userLogs.length != 0) {
        if (timeSheet.data.approved) {
          setEditSheet(false);
        }
        else {
          setEditSheet(true);
        }
        populateDataGrid();
        checkHoursToSubmitTimeSheet();
      }
      else {
        setRows({});
        setEditSheet(true);
      }
      if (timeSheet.data.status.length != 0) {
        setTimeSheetStatus(timeSheet.data.status);
      }
      populateItemList();
      displayConditionalButtons();
    }
  }, [timeSheet, allItemLists])

  React.useEffect(() => {
    axios.get(env.BASE_URL + "/itemlist",{withCredentials: true})
      .then(response => {
        setAllItemLists(response.data);
      });
  }, [timeSheet])

  const checkHoursToSubmitTimeSheet = () => {
    setDisableSubmitButton(true);
    let totalHours = 0;
    for (let i =0; i<timeSheet.data.userLogs.length; i++){
      totalHours += timeSheet.data.userLogs[i].hours;
    }
    if(totalHours >= 40){
      setDisableSubmitButton(false);
    }
  }

  const udapteTimeSheetWeekDays = () => {
    setCurrentDayField("")
    const date = new Date(timeSheet.data.startDate);
    console.log({date})
    const dateArray = []

    for (let i = 0; i < 7; i++) {
      let day = '0' + date.getDate();
      let month = '0' + (date.getMonth() + 1);
      let current = day.slice(-2) + "/" + month.slice(-2);
      dateArray.push(current);
      date.setDate(date.getDate() + 1);
    }
    setTimeSheetWeekDays(dateArray)

    const today = new Date();
    const weekDay = convertFromStringToDate(today)
    const week = getWeekNumber(today)
    const currentWeekNumber = week[0] + "" + week[1]
    if (timeSheet.data.weekId == currentWeekNumber) {
      if (weekDay == "Monday") {
        setCurrentDayField("time")
      }
      if (weekDay == "CurrentDate") {
        setCurrentDayField("time1")
      }
      if (weekDay == "Wednesday") {
        setCurrentDayField("time2")
      }
      if (weekDay == "Thursday") {
        setCurrentDayField("time3")
      }
      if (weekDay == "Friday") {
        setCurrentDayField("time4")
      }
      if (weekDay == "Saturday") {
        setCurrentDayField("time5")
      }
      if (weekDay == "Sunday") {
        setCurrentDayField("time6")
      }
    }
  }

  const createTimeSheet = (currentSelectedDate, userSelectedWeek) => {
    console.log("creating time sheet")
    const date = new Date(currentSelectedDate);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const last = diff + 6


    axios.post(env.BASE_URL + "/timesheet", {
      startDate: new Date(date.setDate(diff)).toISOString(),
      endDate: new Date(date.setDate(diff)).toISOString(),
      weekId: Number(userSelectedWeek[0] + "" + userSelectedWeek[1])
    },{withCredentials: true})
      .then(response => {
        console.log("time sheet created")
        setTimeSheet(response)
      })
  }

  const displayConditionalButtons = () => {
    if (timeSheet.data.user.userId == currentUser.userId) {
      setSubmitTimeSheet(true);
    }
    if (timeSheet.data.user.isAdmin && currentUser.isAdmin) {
      setSubmitButtons(true);
      setItemListHandle(true);
    }
    if (timeSheet.data.user.isManager && currentUser.isAdmin) {
      setSubmitButtons(true);
      setItemListHandle(true);
    }
    if ((!timeSheet.data.user.isManager && !timeSheet.data.user.isAdmin) &&
      (currentUser.isAdmin || currentUser.isManager)
    ) {
      setSubmitButtons(true);
      setItemListHandle(true);
    }
    if (timeSheet.data.user.isManager && currentUser.isManager) {
      setSubmitButtons(false);
      setItemListHandle(true);
    }
    if (!currentUser.isAdmin && !currentUser.isManager) {
      setSubmitButtons(false);
      setItemListHandle(false);
    }
    // else {
    //   setSubmitButtons(false);
    // }
  }

  function convertFromStringToDate(responseDate) {
    var day = new Date(responseDate);
    let weekName = day.getDay();
    var name = "";
    if (weekName == 0) {
      return "Sunday";
    }
    if (weekName == 1) {
      return "Monday";
    }
    if (weekName == 2) {
      return "currentDate";
    }
    if (weekName == 3) {
      return "Wednesday";
    }
    if (weekName == 4) {
      return "Thursday";
    }
    if (weekName == 5) {
      return "Friday";
    }
    if (weekName == 6) {
      return "Saturday";
    }
  }

  var uniqueLogIds = [];

  const populateDataGrid = () => {
    console.log("populate grid")
    console.log(editSheet)
    let localRows = [];
    let totalHours = {}
    let totalTime = { hours: 0 };
    let totalTime1 = { hours: 0 };
    let totalTime2 = { hours: 0 };
    let totalTime3 = { hours: 0 };
    let totalTime4 = { hours: 0 };
    let totalTime5 = { hours: 0 };
    let totalTime6 = { hours: 0 };

    for (let i = 0; i < timeSheet.data.userLogs.length; i++) {
      var userLog = timeSheet.data.userLogs[i];
      if (!uniqueLogIds.includes(userLog.itemList.id)) {
        uniqueLogIds.push(userLog.itemList.id);
        var itemListId = userLog.itemList.id;
        var userLogs = timeSheet.data.userLogs;

        const filtered = userLogs.filter(userLog => {
          return userLog.itemList.id == itemListId;
        });

        let time = {};
        let time1 = {};
        let time2 = {};
        let time3 = {};
        let time4 = {};
        let time5 = {};
        let time6 = {};

        filtered.map((userLog, index) => {
          var logDate = convertFromStringToDate(userLog.date);
          switch (logDate) {
            case "Sunday":
              time6 = { hours: userLog.hours, logId: userLog.id };
              totalTime6.hours += Number(userLog.hours);
              break;
            case "Monday":
              time = { hours: userLog.hours, logId: userLog.id };
              totalTime.hours += Number(userLog.hours);
              break;
            case "currentDate":
              time1 = { hours: userLog.hours, logId: userLog.id };
              totalTime1.hours += Number(userLog.hours);
              break;
            case "Wednesday":
              time2 = { hours: userLog.hours, logId: userLog.id, key: index * Math.random() };
              totalTime2.hours += Number(userLog.hours);
              break;
            case "Thursday":
              time3 = { hours: userLog.hours, logId: userLog.id };
              totalTime3.hours += Number(userLog.hours);
              break;
            case "Friday":
              time4 = { hours: userLog.hours, logId: userLog.id };
              totalTime4.hours += Number(userLog.hours);
              break;
            case "Saturday":
              time5 = { hours: userLog.hours, logId: userLog.id };
              totalTime5.hours += Number(userLog.hours);
              break;
            default:
              console.log("Switch Case Failed");
          }
        })

        const newValues = {
          id: userLog.itemList.id,
          name: userLog.itemList.name,
          time: time,
          time1: time1,
          time2: time2,
          time3: time3,
          time4: time4,
          time5: time5,
          time6: time6,
        }
        localRows.push(newValues)
      }
      totalHours = {
        id: -1,
        name: "Total Hrs",
        time: totalTime,
        time1: totalTime1,
        time2: totalTime2,
        time3: totalTime3,
        time4: totalTime4,
        time5: totalTime5,
        time6: totalTime6,
      }
    }
    localRows.push(totalHours)
    setRows(localRows);
    populateItemList(uniqueLogIds);
  }

  const populateItemList = () => {
    const availableLogIds = [{}];
    const usedLogIds = [{}];
    allItemLists.map((data) => {
      if (data.available || data.available == null) {
        if (!uniqueLogIds.includes(data.id)) {
          availableLogIds.push({
            id: data.id,
            name: data.name
          })
        }
      }
      if (uniqueLogIds.includes(data.id)) {
        usedLogIds.push({
          id: data.id,
          name: data.name
        })
      }
    })
    setAvaiableItemList(() => availableLogIds)
    setAllLocalItemList(() => usedLogIds)
  }

  const handleEditCell = (e, params, logId) => {
    e.preventDefault()
    if (logId != null) {
      const editedLogIndex = timeSheet.data.userLogs.findIndex(log => log.id == logId)
      const newLog = { ...timeSheet.data.userLogs[editedLogIndex], hours: e.target.value };
      var newTimeSheetLogs = [...timeSheet.data.userLogs]
      newTimeSheetLogs[editedLogIndex] = newLog
      const newData = {
        ...timeSheet.data,
        userLogs: newTimeSheetLogs
      }
      const newTestObject = {
        ...timeSheet,
        data: newData
      }
      setTimeSheet(newTestObject)
    }
    else {
      setLogHours(e.target.value)
    }
  }

  const handleCellFocusOut = (params) => {
    var update = true;
    var logDate = "";
    let startDate = timeSheet.data.startDate.substring(0, 10);
    console.log(startDate)
    var newDate = startDate;
    const date = new Date(newDate)
    const currentDate = new Date(date);
    console.log(currentDate)
    switch (params.field) {
      case "time": //MONDAY
        if (params.row.time.logId == null) { update = false };
        logDate = currentDate.getFullYear() + "-" + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (parseInt(currentDate.getDate()) + 1)).slice(-2);
        console.log(logDate)
        break;
      case "time1": //TUESDAY
        if (params.row.time1.logId == null) { update = false };
        logDate = currentDate.getFullYear() + "-" + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (parseInt(currentDate.getDate()) + 2)).slice(-2);
        break;
      case "time2": //WEDNESDAY
        if (params.row.time2.logId == null) { update = false };
        logDate = currentDate.getFullYear() + "-" + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (parseInt(currentDate.getDate()) + 3)).slice(-2);
        console.log(logDate)
        break;
      case "time3": //THURSDAY
        if (params.row.time3.logId == null) { update = false };
        logDate = currentDate.getFullYear() + "-" + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (parseInt(currentDate.getDate()) + 4)).slice(-2);
        console.log(logDate)
        break;
      case "time4": //FRIDAY
        if (params.row.time4.logId == null) { update = false };
        logDate = currentDate.getFullYear() + "-" + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (parseInt(currentDate.getDate()) + 5)).slice(-2);
        break;
      case "time5": //SATURDAY
        if (params.row.time5.logId == null) { update = false };
        logDate = currentDate.getFullYear() + "-" + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (parseInt(currentDate.getDate()) + 6)).slice(-2);
        break;
      case "time6": //SUNDAY
        if (params.row.time6.logId == null) { update = false };
        logDate = currentDate.getFullYear() + "-" + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (parseInt(currentDate.getDate())) + 7).slice(-2);
        break;
    }

    if (update) {
      console.log("put")
      if (params.formattedValue.logId != -1 && params.formattedValue.logId != null) {
        axios.put(env.BASE_URL + "/userlog/" + params.formattedValue.logId, {
          hours: params.formattedValue.hours
        },{withCredentials: true})
          .then(response => {
            setUpdate(current => !current)
          })
          .catch(function (error) {
            const err = error.message;
            if (err.slice(-3) == 403) {
              alert("HOURS EXCEEDING MORE THAN 8")
            }
            else {
              alert(err);
            }
          });
      }
    }
    else {
      if (newLogHours != 0) {
        console.log("post")
        axios.post(env.BASE_URL + "/userlog/week/" + timeSheet.data.weekId + "/itemlist/" + params.id, {
          date: logDate,
          hours: newLogHours
        },{withCredentials: true})
          .then(response => {
            setUpdate(current => !current)
          })
          .catch(function (error) {
            const err = error.message;
            if (err.slice(-3) == 403) {
              alert("HOURS EXCEEDING MORE THAN 8")
            }
            else {
              alert(err);
            }
          });
        setLogHours(0)
      }
    }
  }

  /**
   * method to calculate the total from all the time values
   * in a single row
   * @param {*} params 
   * @returns the total
   */
  const getTotalHrsFrRows = (params) => {
    var time = (params.getValue(params.id, "time").hours)
    var time1 = (params.getValue(params.id, "time1").hours)
    var time2 = (params.getValue(params.id, "time2").hours)
    var time3 = (params.getValue(params.id, "time3").hours)
    var time4 = (params.getValue(params.id, "time4").hours)
    var time5 = (params.getValue(params.id, "time5").hours)
    var time6 = (params.getValue(params.id, "time6").hours)
    var total = 0

    if (time != null) { total += Number(time) }
    if (time1 != null) { total += Number(time1) }
    if (time2 != null) { total += Number(time2) }
    if (time3 != null) { total += Number(time3) }
    if (time4 != null) { total += Number(time4) }
    if (time5 != null) { total += Number(time5) }
    if (time6 != null) { total += Number(time6) }
    return total
  }

  var columns = [
    { field: 'name', headerName: 'Project Name', width: (windowWidth*0.9)/9, editable: false, sortable: false, headerAlign: 'center' },
    {
      field: 'time', type: 'number', width: (windowWidth*0.9)/9, editable: true, sortable: false, textAlign: "center", headerAlign: 'center',
      renderHeader: () => (
        <strong>
          {'MON - '}
          <span> {timeSheetWeekDays[0]} </span>
        </strong>
      ),
      cellClassName: () => {
        if (currentDayField == "time") {
          return 'super-app-theme--cell';
        }
      },
      headerClassName: () => {
        if (currentDayField == "time") {
          return 'super-app-theme--cell';
        }
      },
      renderCell: (params) => (
        params.row.id != -1 ? (
          <TextField
            key={params.row.time.hours}
            fullWidth={true}
            variant="standard"
            // defaultValue="00:00"
            placeholder='0'
            value={params.row.time.hours}
            disabled={!editSheet}
            onChange={(e) => handleEditCell(e, params, params.row.time.logId)}
          />
        ) : params.row.time.hours
      ), align: "center"
    },
    {
      field: 'time1', width: (windowWidth*0.9)/9, type: 'number', editable: true, sortable: false, headerAlign: 'center',
      renderHeader: (params) => (
        <strong>
          {'TUE - '}
          <span> {timeSheetWeekDays[1]} </span>
        </strong>
      ),
      cellClassName: () => {
        if (currentDayField == "time1") {
          return 'super-app-theme--cell';
        }
      },
      headerClassName: () => {
        if (currentDayField == "time1") {
          return 'super-app-theme--cell';
        }
      },
      renderCell: (params) => (
        params.row.id != -1 ? (
          <TextField
            fullWidth={true}
            variant="standard"
            // defaultValue="00:00"
            placeholder='0'
            disabled={!editSheet}
            value={params.row.time1.hours}
            onChange={(e) => handleEditCell(e, params, params.row.time1.logId)}
          />
        ) : params.row.time1.hours
      ), align: "center"
    },
    {
      field: 'time2', width: (windowWidth*0.9)/9, type: 'number', editable: true, sortable: false, headerAlign: 'center',
      renderHeader: (params) => (
        <strong>
          {'WED - '}
          <span> {timeSheetWeekDays[2]} </span>
        </strong>
      ),
      cellClassName: () => {
        if (currentDayField == "time2") {
          return 'super-app-theme--cell';
        }
      },
      headerClassName: () => {
        if (currentDayField == "time2") {
          return 'super-app-theme--cell';
        }
      },
      renderCell: (params) => (
        params.row.id != -1 ? (
          <TextField
            fullWidth={true}
            variant="standard"
            // defaultValue="00:00"
            placeholder='0'
            disabled={!editSheet}
            value={params.row.time2.hours}
            onChange={(e) => handleEditCell(e, params, params.row.time2.logId)}
          />
        ) : params.row.time2.hours
      ), align: "center"
    },
    {
      field: 'time3', width: (windowWidth*0.9)/9, type: 'number', editable: true, sortable: false, headerAlign: 'center',
      renderHeader: (params) => (
        <strong>
          {'THU - '}
          <span> {timeSheetWeekDays[3]} </span>
        </strong>
      ),
      cellClassName: () => {
        if (currentDayField == "time3") {
          return 'super-app-theme--cell';
        }
      },
      headerClassName: () => {
        if (currentDayField == "time3") {
          return 'super-app-theme--cell';
        }
      },
      renderCell: (params) => (
        params.row.id != -1 ? (
          <TextField
            fullWidth={true}
            variant="standard"
            // defaultValue="00:00"
            placeholder='0'
            disabled={!editSheet}
            value={params.row.time3.hours}
            onChange={(e) => handleEditCell(e, params, params.row.time3.logId)}
          />
        ) : params.row.time3.hours
      ), align: "center"
    },
    {
      field: 'time4', width: (windowWidth*0.9)/9, type: 'number', editable: true, sortable: false, headerAlign: 'center',
      renderHeader: (params) => (
        <strong>
          {'FRI - '}
          <span> {timeSheetWeekDays[4]} </span>
        </strong>
      ),
      cellClassName: () => {
        if (currentDayField == "time4") {
          return 'super-app-theme--cell';
        }
      },
      headerClassName: () => {
        if (currentDayField == "time4") {
          return 'super-app-theme--cell';
        }
      },
      renderCell: (params) => (
        params.row.id != -1 ? (
          <TextField
            fullWidth={true}
            variant="standard"
            // defaultValue="00:00"
            placeholder='0'
            disabled={!editSheet}
            value={params.row.time4.hours}
            onChange={(e) => handleEditCell(e, params, params.row.time4.logId)}
          />

        ) : params.row.time4.hours
      ), align: "center"
    },
    {
      field: 'time5', width: (windowWidth*0.9)/9, type: 'number', editable: true, sortable: false, headerAlign: 'center',
      renderHeader: (params) => (
        <strong>
          {'SAT - '}
          <span> {timeSheetWeekDays[5]} </span>
        </strong>
      ),
      headerClassName: () => {
        if (currentDayField == "time5") {
          return 'super-app-theme--cell';
        }
      },
      cellClassName: () => {
        if (currentDayField == "time5") {
          return 'super-app-theme--cell';
        }
      },
      renderCell: (params) => (
        params.row.id != -1 ? (
          <TextField
            fullWidth={true}
            variant="standard"
            // defaultValue="00:00"
            placeholder='0'
            disabled={!editSheet}
            value={params.row.time5.hours}
            onChange={(e) => handleEditCell(e, params, params.row.time5.logId)}
          />
        ) : params.row.time5.hours
      ), align: "center"
    },
    {
      field: 'time6', width: (windowWidth*0.9)/9, type: 'number', editable: true, sortable: false, headerAlign: 'center',
      renderHeader: (params) => (
        <strong>
          {'SUN - '}
          <span> {timeSheetWeekDays[6]} </span>
        </strong>
      ),
      cellClassName: () => {
        if (currentDayField == "time6") {
          return 'super-app-theme--cell';
        }
      },
      headerClassName: () => {
        if (currentDayField == "time6") {
          return 'super-app-theme--cell';
        }
      },
      renderCell: (params) => (
        params.row.id != -1 ? (
          <TextField
            fullWidth={true}
            variant="standard"
            // defaultValue="00:00"
            placeholder='0'
            disabled={!editSheet}
            value={params.row.time6.hours}
            onChange={(e) => handleEditCell(e, params, params.row.time6.logId)}
          />
        ) : params.row.time6.hours
      ), align: "center"
    },
    {
      field: 'time7', headerName: 'Total hours', width: (windowWidth*0.9)/9, type: 'number', editable: true, sortable: false, valueGetter: getTotalHrsFrRows, align: "center", headerAlign: 'center'
    },
  ];

  /**
   * Creating a new item list by admins or managers
   */

  const handleCreateNewItemListItem = () => {
    setOpenCreateNewItemListItem(true);
  }

  const handleCancelCreateNewItemListItem = () => {
    setOpenCreateNewItemListItem(false);
  }

  const handleSubmitCreateNewItemListItem = () => {
    axios.post(env.BASE_URL + "/itemlist", {
      name: newItemListValue
    },{withCredentials: true})
      .then(response => {
        setUpdate(current => !current)
      })
      .catch(function (error) {
        alert(error)
      });
    setLogHours(0)
    setNewItemListValue("")
    setOpenCreateNewItemListItem(false);
  }

  /**
   * Adding a item list to a time sheet
   */
  const handleAddButtonClick = () => {
    setOpenCreateItem(true)
  }

  const handleChangeAddItemList = (event) => {
    setItemListSelected(() => event.target.value)
  }

  const handleCancelAddItemList = () => {
    setOpenCreateItem(false)
  }

  const handleAddItemList = () => {
    var day = new Date(timeSheet.data.endDate);
    var nextDay = new Date(day);
    nextDay.setDate(day.getDate() + 1);
    setOpenCreateItem(false)
    axios.post(env.BASE_URL + "/userlog/week/" + timeSheet.data.weekId + "/itemlist/" + itemListSelected, {
      date: day,
      hours: 0
    },{withCredentials: true})
      .then(response => {
        setUpdate(current => !current)
      })
      .catch(function (error) {
        alert(error)
      });
    setLogHours(0)
  }

  /**
   * Delete a item list from the time sheet
   */
  const handleDeleteButtonClick = () => {
    setOpenDeleteItem(true)
  }

  const handleChangeDeleteItemList = (event) => {
    setItemListSelected(() => event.target.value)
  }

  const handleCancelDeleteItemList = () => {
    setOpenDeleteItem(false)
  }

  const handleDeleteItemList = () => {
    setOpenDeleteItem(false)
    const deleteLogIds = []
    rows.map((row) => {
      if (row.id == itemListSelected) {
        if (row.time.hours != null) { deleteLogIds.push(row.time.logId) }
        if (row.time1.hours != null) { deleteLogIds.push(row.time1.logId) }
        if (row.time2.hours != null) { deleteLogIds.push(row.time2.logId) }
        if (row.time3.hours != null) { deleteLogIds.push(row.time3.logId) }
        if (row.time4.hours != null) { deleteLogIds.push(row.time4.logId) }
        if (row.time5.hours != null) { deleteLogIds.push(row.time5.logId) }
        if (row.time6.hours != null) { deleteLogIds.push(row.time6.logId) }
      }
    })
    deleteLogIds.map(logId => {
      axios.delete( env.BASE_URL + "/userlog/" + logId ,{withCredentials: true} )
        .then(response => {
          setUpdate(current => !current)
        });
    })
    setLogHours(0)
  }

  /**
   * Approve time sheet
   */
  const handleAcceptTimeSheet = () => {
    setOpenApproveDialog(true);
  }

  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
  }

  const handleAcceptApproveDialog = () => {
    axios.post(env.BASE_URL + "/timesheet/" + timeSheet.data.id + "/approved", {
      weekId: timeSheet.data.weekId,
      approved: true
    },{withCredentials: true})
      .then(response => {
        // alert(response.statusText);
        setUpdate(current => !current)
      });
    setLogHours(0)
    setOpenApproveDialog(false);
    navigate("/users")
  }

  /**
   * Reject time sheet
   */
  const handleRejectTimeSheet = () => {
    setOpenRejectDialog(true);
  }

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
  }

  const handleAcceptRejectDialog = (e) => {
    e.preventDefault();
    setOpenRejectDialog(false);
    axios.post(env.BASE_URL + "/timesheet/" + timeSheet.data.id + "/rejected", {
      comment: rejectTimeSheetMessage,
      responsibleUserId: currentUser.userId
    },{withCredentials: true})
      .then(response => {
        alert(response.statusText);
        setUpdate(current => !current)
      });
    setLogHours(0)
  }

  const handleSubmitTimeSheet = () => {
    axios.post(env.BASE_URL + "/timesheet/" + timeSheet.data.id + "/submit", {},{withCredentials: true})
      .then(response => {
        setUpdate(current => !current);
      });
    alert("Timesheet is submitted");
    setLogHours(0)
  }

  /**
   * View All Status
   */
  const handleCloseStatusDialogBox = () => {
    setOpenStatusDialogBox(false);
  }

  const handleOpenStatusDialogBox = () => {
    setOpenStatusDialogBox(true);
  }

  /**
   * Delete a item list from the database
   */

  const handleOpenDeleteItemListFromDatabase = () => {
    setOpenDeleteItemListFromDatabase(true);
  }

  const handleCloseDeleteItemListFromDatabase = () => {
    setOpenDeleteItemListFromDatabase(false);
  }

  const handleDeleteItemListFromDatabase = () => {
    setOpenDeleteItemListFromDatabase(false);

    axios.put(env.BASE_URL + "/itemlist/" + itemListSelected, {
      available: false
    },{withCredentials: true})
      .then(response => {
        setUpdate(current => !current)
      });

    setLogHours(0);
  }

  return (
    <div style={{
      width: '100%',
      height: '100vh',
    }}>
      <NavBar currentUser={currentUser} />
      <br />
      <Typography variant="h4" style={{ padding: '20px', textAlign: 'center' }}>
        Time Sheet
      </Typography>
      {/* TIME SHEET HEADER CONTENT  */}
      <div style={{
        width: '90%',
        margin: 'auto',
        padding: '10px',
      }}>
        <ProjectTableToolBar
          userId={userId}
          weekId={weekId}
          onDelete={handleDeleteButtonClick}
          onEdit={handleAddButtonClick}
          onAdd={handleCreateNewItemListItem}
          onDeleteFromDatabase={handleOpenDeleteItemListFromDatabase}
          itemListHandle={itemListHandle}
          setCurrentSelectedWeek={setUserSelectedWeek}
          setCurrentSelectedDate={setUserSelectedDate}
          editSheet={editSheet}
          timeSheetStatusText={timeSheetStatusText}
        />
        {/* DATA GRID  */}
        <Grid container xs={12}  >
          <Box sx={{
            width: '100%',
            height: '380px',
            overflow: "auto"
          }}>
            <Box
              sx={{
                mt: 1,
                '& .super-app-theme--cell': {
                  backgroundColor: '#ebf6f7',
                  color: '#1a3e72',
                  fontWeight: '600',
                }
              }}
            >
              {timeSheet.data && (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  editRowsModel={editRowsModel}
                  onCellFocusOut={(params) => { handleCellFocusOut(params) }}
                  autoHeight={true}
                  autoPageSize={false}
                  pagination={true}
                  disableColumnMenu={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  key={timeSheet.data.id}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </div>
      {/* DIALOG TO EDIT ITEM LIST  */}
      <Dialog
        open={openCreateItem}
        onClose={handleCancelAddItemList}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: '700', minHeight: '450' }}
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
          Add New Item List
        </DialogTitle>

        <DialogContent>
          <Grid container xs={12} justifyContent='center' alignItems='center' p={2}>
            <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={itemListSelected}
                onChange={handleChangeAddItemList}
              >
                {availableItemList.map(data => {
                  return <MenuItem value={data.id}>{data.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancelAddItemList}>Cancel</Button>
          <Button onClick={handleAddItemList}>Ok</Button>
        </DialogActions>
      </Dialog>
      {/* DIALOG TO CREATE A NEW ITEM LIST ITEM */}
      <Dialog
        open={openCreateNewItemListItem}
        onClose={handleCancelCreateNewItemListItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: '700', minHeight: '450' }}
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
          Create New Item List
        </DialogTitle>

        <DialogContent>
          <Grid container xs={12} justifyContent='center' alignItems='center' p={2}>
            <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
              <TextField
                id="newItemList"
                name="newItemList"
                label="New Item List"
                value={newItemListValue}
                fullWidth
                variant='standard'

                autoFocus
                onChange={(e) => setNewItemListValue(e.target.value)}
              />
            </FormControl>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancelCreateNewItemListItem}>Cancel</Button>
          <Button onClick={handleSubmitCreateNewItemListItem}>Ok</Button>
        </DialogActions>
      </Dialog>
      {/* DIALOG TO DELETE ITEM LIST  */}
      <Dialog
        open={openDeleteItem}
        onClose={handleCancelDeleteItemList}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: '900', minHeight: '450' }}
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
          Remove a item list
        </DialogTitle>

        <DialogContent>
          <Grid container xs={12} justifyContent='center' alignItems='center' p={2}>
            <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={itemListSelected}
                onChange={handleChangeDeleteItemList}
              >
                {allLocalItemList.map(data => {
                  return <MenuItem value={data.id}>{data.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancelDeleteItemList}>Cancel</Button>
          <Button onClick={handleDeleteItemList}>Ok</Button>
        </DialogActions>
      </Dialog>
      {/* DIALOG TO APPROVE TIME SHEET  */}
      <Dialog
        open={openApproveDialog}
        onClose={handleCloseApproveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: '700', minHeight: '450' }}
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
          Approve Time Sheet
        </DialogTitle>

        <DialogContent>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseApproveDialog}>Cancel</Button>
          <Button onClick={handleAcceptApproveDialog}>Ok</Button>
        </DialogActions>
      </Dialog>
      {/* DIALOG TO REJECT TIME SHEET  */}
      <Dialog open={openRejectDialog} onClose={handleCloseRejectDialog}>
        <DialogTitle>Reject Time Sheet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Give a reason for rejecting a time sheet
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            multiline
            value={rejectTimeSheetMessage}
            onChange={(e) => setRejectTimeSheetMessage(e.target.value)}
            rows={5}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejectDialog}>Cancel</Button>
          <Button disabled={rejectTimeSheetMessage.length < 10 ? true : false} onClick={handleAcceptRejectDialog}>Reject</Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG TO VIEW ALL STATUS  */}
      <Dialog
        fullScreen
        open={openStatusDialogBox}
        onClose={handleCloseStatusDialogBox}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Viewing All Status
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseStatusDialogBox}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell align="center">Status By</TableCell>
              <TableCell align="right"> Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeSheetStatus.map((status, index) => {
              return (
                <TableRow>
                  <TableCell>{status.status}</TableCell>
                  <TableCell align="center">{status.responsibleUserName}</TableCell>
                  <TableCell align="right"> {status.comment.comment}</TableCell>
                  <Divider />
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Dialog>

      {/* DIALOG TO DELETE AN ITEM LIST COMPLETELY */}
      <Dialog
        open={openDeleteItemListFromDatabase}
        onClose={handleCloseDeleteItemListFromDatabase}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: '900', minHeight: '450' }}
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
          Delete an item list from the database
        </DialogTitle>

        <DialogContent>
          <Grid container xs={12} justifyContent='center' alignItems='center' p={2}>
            <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={itemListSelected}
                onChange={handleChangeAddItemList}
              >
                {allItemLists.map(data => {
                  return <MenuItem value={data.id}>{data.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDeleteItemListFromDatabase}>Cancel</Button>
          <Button onClick={handleDeleteItemListFromDatabase}>Ok</Button>
        </DialogActions>
      </Dialog>

      <div style={{
        position: 'relative',
        width: "100%",
      }}>

        {submitButtons && (
          <div style={{ float: "left", marginLeft: "35%" }}>
            <Button variant='contained' onClick={handleRejectTimeSheet} > Reject </Button>
          </div>
        )}
        {submitButtons && (
          <div style={{ float: "left", marginLeft: "5%" }}>
            <Button variant='contained' onClick={handleAcceptTimeSheet}> Approve </Button>
          </div>
        )}
        {submitTimeSheet && (
          <div style={{ float: "left", marginLeft: "5%" }}>
            <Button variant='contained' style={{ marginLeft: "0%" }} onClick={handleSubmitTimeSheet} disabled={disableSubmitButton} > Submit </Button>
          </div>
        )}
        {statusViewButton && (
          <div style={{ float: "right", marginRight: "10%" }}>
            <Button variant='contained' onClick={handleOpenStatusDialogBox}> View All Status </Button>
          </div>
        )}

      </div>
      {timeSheet.status == 200 && (
        <DescriptionModified sheet={timeSheet} setChangeUserComments={setChangeUserComments} />
      )}
    </div>
  );
}

function getWeekNumber(dd) {
  if (!dd) return 0
  const d = new Date(Date.UTC(dd.getFullYear(), dd.getMonth(), dd.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return [d.getUTCFullYear(), weekNo];
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});