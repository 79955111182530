import { Outlet, Navigate } from 'react-router-dom'

const ProtectedRoute = () => {
    let auth = {'token':true}
    return(
        auth.token ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default ProtectedRoute




// import { Outlet, Navigate , useNavigate } from 'react-router-dom'


// const useAuth = () => {
//     let navigate = useNavigate();

//     let user: any

// const _user = localStorage.getItem("user")
// if (_user) {
//     user = JSON.parse(_user)
//     console.log("user", user)
 
//    const role = user.role;
//    console.log({role})
// }
// if (user) {
//     return {
//         auth: true,
//         role: user.role,
//     }
// } else {
//     return {
//         auth: false,
//         role: null,
//     }
// }
// }
// type ProtectedRouteType = {
// 	roleRequired?: "admin" | "user"
// }

// const ProtectedRoute = (props: ProtectedRouteType) => {
//     const {auth, role} = useAuth()
//     console.log("abc..")
//   //  let auth = {'token':true}

//   if(props.roleRequired){
     
//         if(props.roleRequired === role)  return  <Outlet />
//         else return null
//    }

// //    if(props.roleRequired){
// //     return auth ? (
// //         props.roleRequired === role ? (
// //             <Outlet />
// //         ) : (
// //             <Navigate to="/login" />
// //         )
// //     ) : (<Navigate to="/login" />)
// //    }
// }

// export default ProtectedRoute

// import React from "react";

// import {Navigate, Outlet} from "react-router-dom";

// const useAuth = () => {
// 	//get item from localstorage

// 	//let user = {firstName : 'Mahesh', lastName: "mmmm", userId : 1,}
//     let user: any

// 	const _user = localStorage.getItem("user")

// 	if (_user) {
// 		user = JSON.parse(_user)
// 		console.log("user", user)
// 	}
// 	if (user) {
// 		return {
// 			auth: true,
// 			role: user.role,
// 		}
// 	} else {
// 		return {
// 			auth: false,
// 			role: null,
// 		}
// 	}
// }

// //protected Route state
// type ProtectedRouteType = {
// 	roleRequired?: "ADMIN" | "USER"
// }

// const ProtectedRoutes = (props: ProtectedRouteType) => {
// 	const {auth, role} = useAuth()

// 	//if the role required is there or not
// 	if (props.roleRequired) {
// 		return auth ? (
// 			props.roleRequired === role ? (
// 				<Outlet />
// 			) : (
// 				<Navigate to="/login" /> 
// 			)
// 		) : (
// 			<Navigate to="/denied" />
// 		)
// 	} else {
// 		return auth ? <Outlet /> : <Navigate to="/login" />
// 	}
// }

// export default ProtectedRoutes
