import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TimeSheetTable from './TimeSheetTable';
import { Grid } from '@mui/material';
import axios from 'axios';
import env from "../../services/ApiServices";

export default function TimeSheetDialog(props) {
  const { userId } = props;

  const [open, setOpen] = React.useState(false);
  const [rows,setRows] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    axios.get(env.BASE_URL + "/timesheet/" + props.userId + "/getallsubmittedsheets", {
      withCredentials: true
    }).then(response => {
      setRows(() => response.data)
    });
  }, []);

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleClickOpen} disabled={!rows.length>0}> 
        Check
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: '700', minHeight: '450' }}
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
          Timesheet
        </DialogTitle>
        <DialogContent>

          <Grid container xs={12} justifyContent='center' alignItems='center' p={2}>
            <TimeSheetTable userId={userId}/>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button style={{ color: '#006DCC ' }} onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}