import React from 'react'
import Projecttable from './features/userLogbook/Projecttable';
import UserComponent from './features/userLogbook/UserComponent';
import TimeSheetDialog from './features/UserTable/TimeSheetDialog';
import UserLeaveTable from './features/UserTable/UserLeaveTable';

import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

import './App.css'

import LoginPage from './features/Login/LoginPage';
import Userinfo from './features/userInfoForm/index'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import UserLeave from './features/userLeave';
import Logout from './features/logout/Logout';
import Startup from './features/Login/Startup';
import ReportPage from './features/ReportPage/ReportPage';


const theme = createTheme();






function App() {

  // const user = localStorage.getItem("user")
  // const role = user.role;
  // console.log({role})

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<LoginPage />} />
              <Route path="/users" element={<UserLeaveTable />} />

              {/* <Route path="/usercomponent" element={<UserComponent />} /> */}
              <Route path="/userinfo/user" element={<Userinfo />} />
              <Route path="/userinfo/userleave" element={<UserLeave />} />
              <Route path="/userinfo/userleave/:userId" element={<UserLeave />} />
              <Route path="/projecttable/user/:userId/week/:weekId" element={<Projecttable />} />
              <Route path="/projecttable" element={<Projecttable />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/reportpage' element={<ReportPage />} />
          </Routes>
        </Router>

      </div>
    </ThemeProvider>
  )
}

export default App