import React from 'react'
import { Formik, Form } from 'formik'

import FormikControl from '../../components/formikComponents/FormikControl'
import { ImageInput } from "formik-file-and-image-input/lib";
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import GridItem from '../../components/Grid/GridItem';
import Button from '@mui/material/Button';

function CustomImageInputWrapper({ onClick, fileName, src }) {
  return (
    <div onClick={onClick}>
      {!src && <button onClick={onClick}>Choose Image</button>}
      <img src={src} />
      <p>{fileName}</p>
    </div>
  )
}

function Biodata(props) {

  const { isViewMode, viewPassword ,hideEmail} = props

  const dropdownOptions = [
    { key: 'Select an option', value: '' },
    { key: 'MALE', value: 'MALE' },
    { key: 'FEMALE', value: 'FEMALE' }

  ]
  const imageFormats = ["image/png", "image/svg", "image/jpeg"];




  return (


    <CardContent style={{
      backgroundColor: 'rgb(5%, 75%, 86%)',
      borderRadius: '10px',
      marginBottom: '10px'
    }}>
      <Typography gutterBottom variant="h5" component="div">
        Bio Data
      </Typography>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='input'
          label='First Name'
          name='firstName'
          disabled={isViewMode}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='input'
          label='Last Name'
          name='lastName'
          disabled={isViewMode}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='input'
          label='Email'
          name='email'
          disabled={isViewMode || hideEmail}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>

      {!viewPassword && (
        <GridItem xs={12} sm={12} md={6}>
          <FormikControl
            control='input'
            label='Temporary password'
            type="password"
            name='password'
            disabled={isViewMode}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
      )}



      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='date'
          label='Birthday'
          name='dateOfBirth'
          disabled={isViewMode}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='select'
          label='Select the gender'
          name='gender'
          disabled={isViewMode}
          options={dropdownOptions}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>

      {/* <GridItem xs={12} sm={12} md={6}>
              <ImageInput
                name="image"
                validFormats={imageFormats}
                component={CustomImageInputWrapper}
              />
            </GridItem> */}


    </CardContent>


  )
}

export default Biodata
