import { Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react'
import { Box } from '@mui/system';
import "./NavBar.css";
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';


export default function NavBar(props) {
    const navigate = useNavigate();

    const { currentUser } = props;

    const [openSideBar, setOpenSideBar] = React.useState(false);

    const openMenu = () => {
        setOpenSideBar(true)
    }

    const closeMenu = () => {
        setOpenSideBar(false)
    }

    const handleClick = (route) => {
        navigate(route)
        setOpenSideBar(false)
    }

    return (
        <div className='bar'>
            <div className='icon'>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={openMenu} sx={{ ml: 5 }}>
                    <MenuIcon sx={{ fontSize: 40 }} />
                </IconButton>
            </div>

            <Drawer
                open={openSideBar}
                onClose={closeMenu}
            >   <div className='drawer'>
                    <Box
                        sx={{ width: 300 }}
                    >
                        <ul className='navbar' >
                            {currentUser && (currentUser.isAdmin || currentUser.isManager) && (
                                <>
                                    <button onClick={() => handleClick("/users")}>
                                        <p>View All Users </p>
                                    </button>
                                    <button onClick={() => handleClick("/userinfo/user?adduser=true")}>
                                        <p>Add User </p>
                                    </button>
                                </>

                            )}
                            <button onClick={() => handleClick("/userinfo/user?profile=true")}>
                                <p>Profile </p>
                            </button>
                            <button onClick={() => handleClick("/userinfo/userleave")}>
                                <p>Check Leave </p>
                            </button>
                            <button onClick={() => handleClick("/projecttable")}>
                                <p>View Timesheet</p>
                            </button>
                            <button onClick={() => handleClick("/reportPage")}>
                                <p> Report </p>
                            </button>
                            <button onClick={() => handleClick("/logout")}>
                                <p> Signout </p>
                            </button>

                        </ul>
                    </Box>
                    <div className='closeButton'>
                        <IconButton onClick={closeMenu} >
                            <CancelIcon sx={{ fontSize: 60 }} />
                        </IconButton>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}