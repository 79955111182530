import React, { useEffect, useState } from 'react'
import { Box, Grid, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';




function ProjectTableToolBar(props) {
    const { timeSheetStatusText, onDelete, onEdit, onAdd, onDeleteFromDatabase, itemListHandle, editSheet, onChangeDate, setCurrentSelectedWeek, userId, weekId,setCurrentSelectedDate } = props

    const [openCalender, setOpenCalender] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [weekStaringEndingDate, setWeekStaringDate] = useState(getFirstWeekDay(new Date()))

    const navigate = useNavigate();

    const handleClick = (event) => {
        setOpenCalender(!openCalender)
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if(weekId){
            let year = weekId.substring(0,4);
            let weekNumber = weekId.substring(4, 6) ;
            var d = (1 + (weekNumber) * 7);
            setWeekStaringDate(getFirstWeekDay(new Date(year, 0, d)))
        }
        else {
            setWeekStaringDate(getFirstWeekDay(selectedDate))
        }
        
    }, [selectedDate,weekId])

    const handleChangeDate = (date) => {
        setSelectedDate(date)
        setCurrentSelectedDate(date)
        console.log(getWeekNumber(date))
        setCurrentSelectedWeek(getWeekNumber(date))
        if (userId != null) {
            navigate("/projecttable/user/" + userId + "/week/" + getWeekNumber(date)[0] + getWeekNumber(date)[1])
        }
    }



    //{getWeekNumber(selectedDate)} 

    return (
        <Grid container>
            <h3>
                    TimeSheet Status : {timeSheetStatusText} 
            </h3>
            <Grid container xs={12} justifyContent='start' alignItems='end' >
                <Box sx={{
                    marginRight: '50px'
                }}>
                    <Typography>
                        From: {weekStaringEndingDate[0].toDateString()}
                    </Typography>
                </Box>


                <Typography>
                    To:  {weekStaringEndingDate[1].toDateString()}
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        //label="From"
                        //inputFormat="MM/dd/yyyy"
                        value={selectedDate}
                        open={openCalender}
                        renderInput={params => <IconButton {...params} onClick={handleClick}>
                            <CalendarMonthIcon />
                        </IconButton>}
                        PopperProps={{ anchorEl: anchorEl }}
                        //closeOnSelect = {true}
                        disableFuture={true}
                        onChange={handleChangeDate}
                        // onAccept = {() => setOpenCalender(false)}
                        onClose={() => {
                            console.log("on close")
                            setOpenCalender(false)
                        }
                        }
                    />
                </LocalizationProvider>
                
            </Grid >
            {editSheet && (
                <Grid container xs={12} justifyContent='end' alignItems='end' >

                    <Tooltip title="Remove" placement="top">
                        <IconButton aria-label="delete" onClick={onDelete} >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                        <IconButton aria-label="edit" onClick={onEdit} >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    {itemListHandle && (
                        <>
                            <Tooltip title="Add" placement="top">
                                <IconButton aria-label="add" onClick={onAdd} >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top">
                                <IconButton aria-label="add" onClick={onDeleteFromDatabase} >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>

                    )}
                </Grid>
            )}
        </Grid>
    )
}

export default ProjectTableToolBar

function getWeekNumber(dd) {
    if (!dd) return 0

    const date = new Date(dd);
    const day = date.getDay();
    const firstDay = date.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(date.setDate(firstDay))

    // Copy date so don't modify original
    const d = new Date(Date.UTC(monday.getFullYear(), monday.getMonth(), monday.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    console.log(yearStart)
    // Calculate full weeks to nearest Thursday
    const tesst1 = Math.ceil(d - yearStart);
    console.log(tesst1)
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    console.log(weekNo)
    console.log(d)
    console.log((d - yearStart) / 86400000)
    // Return array of year and week number

    console.log(weekNo)
    return [d.getUTCFullYear(), weekNo];
}

function getFirstWeekDay(dd) {
    if (!dd) return;
    const d = new Date(Date.UTC(dd.getFullYear(), dd.getMonth(), dd.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)

    const startDate = new Date(d.getTime() - 24 * 60 * 60 * 1000 * 3);
    // console.log(d)
    // console.log(startDate)

    const endDate = new Date(d.getTime() + 24 * 60 * 60 * 1000 * 3);
    // console.log(endDate)

    return [startDate, endDate]
}