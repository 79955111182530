import React from "react";
import { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, FormHelperText, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { makeStyles } from "@mui/styles";



import { useNavigate } from 'react-router-dom';
import axios from "axios";
import env from "../../services/ApiServices";
import { tr } from "date-fns/locale";

const useStyles = makeStyles({
  root: {


  },
  paperA: {

    maxWidth: ' 600px',
    minWidth: '300px',
    maxHeight: '700px',
    width: '600px',
    height: '60%',
    margin: '100px auto',
    borderRadius: '25px',


  },
  '@global': {
    body: {
      backgroundColor: "#40e0d0",
      Opacity: ' 0.5 ',
    },
  },

});


export default function LoginPage(props) {
  const [failed, setFailed] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);

  const classes = useStyles();
  const handleEmailChange = (event) => {
    setIncorrectCredentials(false);
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setIncorrectCredentials(false);
    setPassword(event.target.value);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(env.BASE_URL + "/signin",
      {
        username: email,
        password: password
      },
      {withCredentials: true}
    )
      .then(response => {
        console.log(response)
        if (response.data.isAdmin) {
          navigate("/users")
        }
        else if (response.data.isManager) {
          navigate("/users")
        }
        else {

          navigate("/projecttable")
        }
      })
      .catch(function (error) {
        setIncorrectCredentials(true);
        setEmail("");
        setPassword("");
      });
  }




  return (



    <Paper style={{
      // position: 'absolute',
      //left: '50%',
      //top: '35%',
      //transform: 'translate(-50%, -50%)',
      // boxShadow:'3px 4px #6495ED',
      width: '600px'

    }}
      className={classes.paperA}
    >
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Grid container xs={12} alignItems='center' justifyContent='center' direction='row'>
          <Grid item  >
            <Avatar sx={{ bgcolor: 'primary.main' }} />
          </Grid>
          <Grid container xs={12} alignItems='center' justifyContent='center' direction='row'>
            <Grid item>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <img className={classes.logo} src="" alt="Sutra HRM" /> */}
        <form onSubmit={handleSubmit} >
          <Grid container xs={12} fullwidth direction='row' p={2} alignItems='center' justifyContent='center' >

            {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
               // type={values.showPassword ? 'text' : 'password'}
             //   value={values.password}
              //  onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      //onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {true ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl> */}
            {incorrectCredentials && (
              <h3 style={{ color: 'red', textAlign: 'center' }}> Incorrect Credentials </h3>
            )}
            <FormControl margin="normal" required fullWidth error={failed}>

              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailRoundedIcon color="primary" />
                    </InputAdornment>
                  ),
                }}

                margin="normal"
                required

                id="email"
                name="email"
                label="Email Address"
                value={email}
                autoComplete="email"
                autoFocus
                onChange={handleEmailChange}
              />


              {failed && <FormHelperText>Invalid username or password</FormHelperText>}

            </FormControl>

            <FormControl margin="normal" required fullWidth>

              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon color="primary" />
                  </InputAdornment>
                }

                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                    </IconButton>
                  </InputAdornment>
                }

                label="Password"
              />

            </FormControl>
          </Grid>
          <Grid container xs={12} p={2} fullwidth>
            <Grid item xs={12} fullwidth>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!email || !password}
                fullWidth
              >
                {'Login'}
              </Button>
            </Grid>
          </Grid>

        </form>
      </Box>
    </Paper>

  )
}

// import { useRef, useState, useEffect } from 'react';
// import useAuth from '../../hooks/useAuth';
// //import useAuth from '../hooks/useAuth';
// import { Link, useNavigate, useLocation } from 'react-router-dom';

// // axios from '../api/axios';
// //import axios from '../../api/axios';
// const LOGIN_URL = '/auth';

// const Login = () => {
//     const { setAuth } = useAuth();

//     const navigate = useNavigate();
//     const location = useLocation();
//     const from = location.state?.from?.pathname || "/";

//     const userRef = useRef();
//     const errRef = useRef();

//     const [user, setUser] = useState('');
//     const [pwd, setPwd] = useState('');
//     const [errMsg, setErrMsg] = useState('');

//     useEffect(() => {
//         userRef.current.focus();
//     }, [])

//     useEffect(() => {
//         setErrMsg('');
//     }, [user, pwd])

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post(LOGIN_URL,
//                 JSON.stringify({ user, pwd }),
//                 {
//                     headers: { 'Content-Type': 'application/json' },
//                     withCredentials: true
//                 }
//             );
//             console.log(JSON.stringify(response?.data));
//             //console.log(JSON.stringify(response));
//             const accessToken = response?.data?.accessToken;
//             const roles = response?.data?.roles;
//             setAuth({ user, pwd, roles, accessToken });
//             setUser('');
//             setPwd('');
//             navigate(from, { replace: true });
//         } catch (err) {
//             if (!err?.response) {
//                 setErrMsg('No Server Response');
//             } else if (err.response?.status === 400) {
//                 setErrMsg('Missing Username or Password');
//             } else if (err.response?.status === 401) {
//                 setErrMsg('Unauthorized');
//             } else {
//                 setErrMsg('Login Failed');
//             }
//             errRef.current.focus();
//         }
//     }

//     return (

//         <section>
//             <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
//             <h1>Sign In</h1>
//             <form onSubmit={handleSubmit}>
//                 <label htmlFor="username">Username:</label>
//                 <input
//                     type="text"
//                     id="username"
//                     ref={userRef}
//                     autoComplete="off"
//                     onChange={(e) => setUser(e.target.value)}
//                     value={user}
//                     required
//                 />

//                 <label htmlFor="password">Password:</label>
//                 <input
//                     type="password"
//                     id="password"
//                     onChange={(e) => setPwd(e.target.value)}
//                     value={pwd}
//                     required
//                 />
//                 <button>Sign In</button>
//             </form>
//             <p>
//                 Need an Account?<br />
//                 <span className="line">
//                     <Link to="/register">Sign Up</Link>
//                 </span>
//             </p>
//         </section>

//     )
// }

// export default Login
