import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Button from '@mui/material/Button';

import Biodata from './Biodata'
import ContactDetails from './ContactDetails'
import EducationQualification from './EducationQualification'
import ManagerialRoles from './ManagerialRoles';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import NavBar from '../navigationBar/NavBar';
import './UserInfoForm.css';
import env from "../../services/ApiServices";

var formikey = 1
function Userinfo({ props }) {
    const [currentUser, setCurrentUser] = React.useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    const [isViewMode, setIsViewMode] = useState(false)
    const [submitButtonText, setSubmitButtonText] = useState("Add user")
    const [initialValues, setInitialValues] = useState();
    const [hidePassword, setHidePassword] = useState(false);
    const [hideEmail, setHideEmail] = useState(false);
    const [hideNewPasswordButton, setHideNewPasswordButton] = useState(false);
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [update, setUpdate] = useState(false);

    var values = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        gender: 'MALE',
        dateOfBirth: null,
        contactNumberOne: '07',
        contactNumberTwo: '',
        emergencyContactNumber: '',
        description: '',
        isAdmin: false,
        isManager: false,
        supervisorId: -1,
        title: '',
    }

    const mode = searchParams.get('mode');
    const userId = searchParams.get('userId');
    const addUser = searchParams.get('adduser');
    const userProfile = searchParams.get('profile');

    React.useEffect(() => {
        axios.get(env.BASE_URL + "/user", { withCredentials: true })
            .then(response => {
                setCurrentUser(response.data);
            })
    }, [])

    useEffect(() => {
        console.log("running use effect")
        if (addUser) {
            setIsViewMode(false)
            setInitialValues(values)
        }
        if (userProfile) {
            axios.get(env.BASE_URL + "/user/" + currentUser.userId, { withCredentials: true })
                .then(response => {
                    const values = response.data;
                    var newValues = values
                    if (values.dateOfBirth != null) {
                        const date = new Date(values.dateOfBirth);
                        newValues = { ...values, dateOfBirth: date }
                    }
                    setInitialValues(newValues)
                    setIsViewMode(false)
                    setSubmitButtonText("Update profile")
                    setHidePassword(true);
                    setHideEmail(true);
                    setHideNewPasswordButton(true);
                })
        }
        if (mode && (mode === 'view' || mode === 'edit')) {
            axios.get(env.BASE_URL + "/user/" + userId, { withCredentials: true })
                .then(response => {
                    const values = response.data;
                    var newValues = values
                    if (values.dateOfBirth != null) {
                        const date = new Date(values.dateOfBirth);
                        newValues = { ...values, dateOfBirth: date }
                    }
                    setInitialValues(newValues)
                    if (mode === 'view') {
                        setIsViewMode(true)
                    }
                    if (mode == "edit") {
                        setSubmitButtonText("Update user")
                    }
                    setHidePassword(true);
                })
        }
        formikey++; // force to update 
    }, [mode, userId, currentUser, userProfile, addUser, update])

    const validationSchema = Yup.object({
        email: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        contactNumberOne: Yup.string().when('modeOfContact', {
            is: 'telephonemoc',
            then: Yup.string().required('Required')
        }),
        emergencyContactNumber: Yup.string().when('modeOfContact', {
            is: 'telephonemoc',
            then: Yup.string().required('Required')
        })
    })

    const onSubmit = (values) => {
        console.log(values)
        const newValues = { ...values, dateOfBirth: new Date(values.dateOfBirth).toISOString().split('T')[0] }
        const mode = searchParams.get('mode');
        console.log(userProfile)
        if (mode == "edit" || userProfile) {
            let userId = searchParams.get('userId')
            if (userProfile) {
                userId = currentUser.userId
            }
            console.log(newValues)
            axios.put(env.BASE_URL + "/user/" + userId, {
                isAdmin: newValues.isAdmin,
                isManager: newValues.isManager,
                supervisorId: newValues.supervisorId,
                firstName: newValues.firstName,
                lastName: newValues.lastName,
                gender: newValues.gender,
                dateOfBirth: newValues.dateOfBirth,
                contactNumberOne: newValues.contactNumberOne,
                contactNumberTwo: newValues.contactNumberTwo,
                emergencyContactNumber: newValues.emergencyContactNumber,
                urlToProfile: newValues.urlToProfile,
                email: newValues.email,
                password: newValues.password,
                totalNoOfLeaves: newValues.totalNoOfLeaves,
                title: newValues.title,
                team: newValues.team,
                projectList: newValues.projectList,
                jobType: newValues.jobType,
                educationQualification: newValues.educationQualification
            }, { withCredentials: true })
                .then(response => {
                    alert(response.data);
                })
                .catch(function (error) {
                    alert(error)
                })

        }
        else {
            console.log(newValues)
            axios.post(env.BASE_URL + "/user", { 
                isAdmin:newValues.isAdmin,
                isManager:newValues.isManager,
                supervisorId:newValues.supervisorId,
                firstName:newValues.firstName,
                lastName:newValues.lastName,
                gender:newValues.gender,
                dateOfBirth:newValues.dateOfBirth,
                contactNumberOne:newValues.contactNumberOne,
                contactNumberTwo:newValues.contactNumberTwo,
                emergencyContactNumber:newValues.emergencyContactNumber,
                urlToProfile:newValues.urlToProfile,
                email:newValues.email,
                password:newValues.password,
                totalNoOfLeaves:newValues.totalNoOfLeaves,
                title:newValues.title,
                team:newValues.team,
                projectList:newValues.projectList,
                jobType:newValues.jobType,
                educationQualification:newValues.educationQualification
            }, { withCredentials: true })
                .then(response => {
                    alert(response.data);
                })
                .catch(function (error) {
                    alert("USER EMAIL EXISTS")
                })
        }
        setUpdate((update) => !update);
    }

    const handleChangePassword = () => {
        setOpenChangePasswordDialog(true);
    }

    const handleCloseChangePasswordDialog = () => {
        setOpenChangePasswordDialog(false);
    }

    const handleSubmitChangePasswordDialog = () => {
        axios.put(env.BASE_URL + "/user/changepassword/" + newPassword, {}, { withCredentials: true })
            .then((response) => {
                alert("Updated Password");
            })
            .catch(function (error) {
                alert(error)
            })
        setOpenChangePasswordDialog(false);
    }

    return (
        <div style={{ marginBottom: '50px' }}>
            <NavBar currentUser={currentUser} />
            <br />
            <div className='content'>
                <Typography gutterBottom variant="h4" component="div" textAlign='center'>
                    USER INFORMATION
                </Typography>

                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    key={formikey}
                >
                    {formik => (

                        <Form>
                            <Biodata formik={formik} isViewMode={isViewMode} viewPassword={hidePassword} hideEmail={hideEmail} />
                            <ContactDetails formik={formik} isViewMode={isViewMode} />
                            <EducationQualification formik={formik} isViewMode={isViewMode} />

                            {(currentUser.isAdmin) && (
                                <ManagerialRoles formik={formik} isViewMode={isViewMode} />
                            )}

                            {!isViewMode && <Grid
                                container
                                justifyContent="center"
                            >
                                <Button variant="contained" disableElevation type="submit">
                                    {submitButtonText}
                                </Button>
                                {hideNewPasswordButton && (
                                    <Button variant="contained" disableElevation style={{ marginLeft: 20 }} onClick={handleChangePassword}>
                                        Change Password
                                    </Button>
                                )}
                            </Grid>}
                        </Form>)
                    }
                </Formik >
            </div>
            <Dialog
                open={openChangePasswordDialog}
                onClose={handleCloseChangePasswordDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ minWidth: '700', minHeight: '450' }}
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
                    Change Password
                </DialogTitle>

                <DialogContent>
                    <Grid container xs={12} justifyContent='center' alignItems='center' p={2}>
                        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                            <TextField
                                id="newpassword"
                                name="newpassword"
                                label="New Password"
                                value={newPassword}
                                fullWidth
                                variant='standard'
                                type="password"
                                autoFocus
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseChangePasswordDialog}>Cancel</Button>
                    <Button onClick={handleSubmitChangePasswordDialog} disabled={newPassword.length < 3}>Ok</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}
export default Userinfo