import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import env from "../../services/ApiServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



export default function TimeSheetTable(props) {
  let navigate = useNavigate();
  const [rows,setRows] = React.useState([]);
  React.useEffect(() => {
    axios.get(env.BASE_URL + "/timesheet/" + props.userId + "/getallsubmittedsheets", {
      withCredentials: true
    }).then(response => {
      setRows(() => response.data)
    });
  }, []);

  const handleClick = (row) => {
    console.log(row)
    navigate(`/projecttable/user/${props.userId}/week/${row.weekId}`)
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{width: 500 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{width:150}}>From Date</StyledTableCell>
            <StyledTableCell style={{width:150}} align="left">To Date</StyledTableCell>
            <StyledTableCell style={{width:200}} align="center">Status</StyledTableCell>
            </TableRow>
           
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                {row.startDate}
              </StyledTableCell>
              <StyledTableCell align="left">{row.endDate}</StyledTableCell>
              <StyledTableCell align="center">
              <Button variant='contained' size="small" color='success' onClick={() => handleClick(row)}> Check </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
