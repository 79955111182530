import {Button, Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGrid} from '@mui/x-data-grid';
import NavBar from '../navigationBar/NavBar';
import dayjs from 'dayjs';
import axios from 'axios';
import {GridToolbarContainer,GridToolbarExport,} from '@mui/x-data-grid-premium';
import env from '../../services/ApiServices';

function ReportPage() {
  const [currentUser, setCurrentUser] = React.useState([]);

  const todayDate = dayjs();
  const firstDayMonth = todayDate.startOf("month");
  const lastDayMonth = todayDate.endOf("month");
  const [selectedFromDate, setSelectedFromDate] = useState(firstDayMonth.toDate()); 
  const [selectedToDate, setSelectedToDate] = useState(lastDayMonth.toDate()); 
  const [rows, setRows] = useState([]);
  
  const handleDateChangeFrom = (date) => {
   setSelectedFromDate(date)
  };

  const handleDateChangeTo = (date) => {
    setSelectedToDate(date)
   };

   const handleOnClick = () => {
    
    const startDate = dayjs(selectedFromDate).format('YYYY-MM-DD');
    const endDate = dayjs(selectedToDate).format('YYYY-MM-DD');

     const baseURL = `userlog/summary?startDate=${startDate}&endDate=${endDate}`;
      setRows([]);
      axios.get( env.BASE_URL + "/" + baseURL).then((response) => {
        // axios.get( "").then((response) => {
        
          const updatedRows = response.data.map((itemList,i) => ({
          id: i, 
          name: itemList.itemList.name ,
          hour: itemList.hours,
         
        }));
 
        setRows(updatedRows); 
        
      })
};

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
      );
    }

   var columns = [
    {
      field: "name",
      headerName: "Item Name",
      width:800,
      editable: false,
      sortable: false,
      headerAlign: "center",
    },

    {
      field: "hour",
      headerName: "Total hours",
      type: "number",
      editable: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      display:'flex',
      flex:1
          },
  ];

  return (
    <>
      <NavBar currentUser={currentUser} />
      <Grid
        container
        xs={12}
        justifyContent="start"
        alignItems="end"
        style={{ marginLeft: "5%" }}
      >
        <Grid item xs={6} sm={6} style={{ marginTop: "5%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From"
              value={selectedFromDate}
              onChange={handleDateChangeFrom}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="To"
              value={selectedToDate}
              onChange={handleDateChangeTo}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              marginBottom: "20px",
              width: "20%",
              padding: "10px",
              marginRight: "20%",
            }}
            onClick={handleOnClick}
          >
            Search
          </Button>
        </Grid>


        <div
          style={{
            height: 400,
            width: "100%",
            marginTop: "30px",
            marginRight: "10%",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooter={true}
            components={{ Toolbar: CustomToolbar }}
          />
        </div>
      </Grid>
    </>
  );
}


export default ReportPage

