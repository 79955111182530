import React from 'react'
import FormikInput from './FormikInput';
import FormikTextarea from './FormikTextarea'
import FormikSelect from './FormikSelect'
import FormikRadioButtons from './FormikRadioButtons'
import FormikCheckboxGroup from './FormikCheckboxGroup'
import FormikDatePicker from './FormikDatePicker'
import FormikCheckBox from './FormikCheckBox';


function FormikControl (props) {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <FormikInput {...rest} />
    case 'textarea':
      return <FormikTextarea {...rest} />
    case 'select':
      return <FormikSelect {...rest} />
    case 'radio':
      return <FormikRadioButtons {...rest} />
    case 'checkBox':
        return <FormikCheckBox  {...rest}/>
    case 'checkboxGroup':
      return <FormikCheckboxGroup {...rest} />
    case 'date':
      return <FormikDatePicker {...rest} />
 
    default:
      return null
  }
}

export default FormikControl


