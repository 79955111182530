import React, { useState } from "react";
import { Grid, ClickAwayListener } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import env from "../../services/ApiServices";

function DescriptionModified(props) {

    const { sheet, setChangeUserComments } = props;

    const [comments, setComments] = useState([])
    const [activeCommentIndex, setActiveCommentIndex] = useState(null)
    const [editModeOn, setEditModeOn] = useState(false)
    const [readOnly, setReadOnly] = useState(true);
    const [newComment, setNewComment] = useState("");
    const [update, setUpdate] = useState(true);
    const [onAdd, setOnAdd] = useState(false);

    React.useEffect(() => {
        if (sheet.data) {
            if (sheet.data.userComments.length != 0) {
                setComments([...sheet.data.userComments].sort((a, b) => b.commentId - a.commentId));
            }
            else {
                setComments([]);
            }
        }
    }, [sheet, update])

    React.useEffect(() => {
      if(onAdd && activeCommentIndex == 0){
        setReadOnly(false);
      }
      else {
        setUpdate((update) => !update)
        setChangeUserComments((changeUserComments) => !changeUserComments)
        setOnAdd(false);
        setReadOnly(true);
      }
    }, [activeCommentIndex])
    
    const onAddComment = () => {
        setOnAdd(true);
        const newComment = {
            comment: ""
        }
        setComments([newComment, ...comments])
        setActiveCommentIndex(0);
    }

    const handleDelete = () => {
        var currentComment = comments[activeCommentIndex];
        axios.delete(env.BASE_URL + "/comments/" + currentComment.commentId,{},{withCredentials: true})
            .then(response => {
                setComments(response.data)
            });
        setChangeUserComments((changeUserComments) => !changeUserComments)
        setActiveCommentIndex(null);
    }

    const handleEditComment = (e, comment) => {
        setNewComment(e.target.value)
        if (activeCommentIndex >= 0 && comment != "") {
            var oldComments = [...comments]
            oldComments[activeCommentIndex] = { ...oldComments[activeCommentIndex], comment: e.target.value }
            setComments(oldComments)
        }
    }

    const handleBlur = () => {
        console.log("onBlur")
        var oldComments = [...comments]
        if (oldComments[activeCommentIndex].commentId == null && newComment != "") {
            axios.post(env.BASE_URL + "/comments/week/" + sheet.data.weekId, {
                comment: newComment
            },{withCredentials: true})
                .then(response => {
                    setComments(response.data)   
                    setNewComment("")     
                });
        }
        setUpdate((update) => !update)
        setChangeUserComments((changeUserComments) => !changeUserComments)
        setOnAdd(false);
    }

    const handleOnSave = () => {
        console.log(newComment)
        axios.put(env.BASE_URL + "/comments/" + comments[activeCommentIndex].commentId, {
            comment: newComment
        },{withCredentials: true})
            .then(response => {
                setComments(response.data)
                setNewComment("")
            });
        setChangeUserComments((changeUserComments) => !changeUserComments)
        setEditModeOn(false)
        setReadOnly(true)
    }

    const handleClickAway = () => {
        console.log("click away")
        console.log(newComment)
        console.log(comments)
        setUpdate((update) => !update)
        setChangeUserComments((changeUserComments) => !changeUserComments)
    }

    const onDiscard = () => {
        setEditModeOn(false)
        setReadOnly(true)
        setUpdate((update) => !update)
        setChangeUserComments((changeUserComments) => !changeUserComments)
    }

    const handleOnEdit = (e) => {
        setEditModeOn(true)
        setReadOnly(false)
        setNewComment(e.target.value)
        console.log(e.target.value)
    }

    const handleClick = (e,i) => {
        setActiveCommentIndex(i)
        setNewComment(e.target.value)
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <Grid container xs={12} >
                        <Grid container xs={6} justifyContent='start' alignItems='start'>
                            <Typography variant="h6" >
                                &nbsp;&nbsp;Description
                            </Typography>
                        </Grid>
                        {!editModeOn ? (
                            <Grid container xs={6} justifyContent='end' alignItems='end' >
                                <Tooltip title="Add">
                                    <IconButton aria-label="add" onClick={onAddComment}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                                {activeCommentIndex >= 0 && <Tooltip title="Edit">
                                    <IconButton aria-label="edit" onClick={handleOnEdit}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>}

                                {activeCommentIndex >= 0 && <Tooltip title="Delete">
                                    <IconButton aria-label="delete" onClick={handleDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>}
                            </Grid>
                        ) : (
                            <Grid container xs={6} justifyContent='end' alignItems='end' >
                                <Tooltip title="Save">
                                    <IconButton aria-label="add" onClick={handleOnSave} >
                                        <SaveIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Discard">
                                    <IconButton aria-label="add" onClick={onDiscard}>
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                    {
                        comments.map((comment, i) => {
                            return (
                                <Grid container xs={12} fullwidth p={1} key={i}>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        fullWidth
                                        size='large'
                                        value={comment.comment}
                                        onChange={(e) => handleEditComment(e, comment)}
                                        rows={3}
                                        multiline
                                        // onFocus={() => setActiveCommentIndex(i)}
                                        onClick={(e) => handleClick(e,i) }
                                        onBlur={handleBlur}
                                        readOnly={readOnly}                                 
                                    />
                                </Grid>
                            )
                        })
                    }


                    {/* <Grid container xs={12} justifyContent='end' alignItems='end' p={1} >
                    <Button variant="contained"> save </Button>
                    &nbsp; &nbsp;
                    <Button variant="contained"> Cancel </Button>
                </Grid> */}
                </div>
            </ClickAwayListener>
        </>
    )
}

export default DescriptionModified;
