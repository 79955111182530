import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import LockResetIcon from '@mui/icons-material/LockReset';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import env from "../../../services/ApiServices";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'grey',
    '&:hover': {
        backgroundColor: 'grey',
    },
    border: true,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {

            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function UserTableToolbar(props) {
    const { selected, setUpdate, rows ,setSelected} = props;
    const numSelected = selected.length
    const navigate = useNavigate()

    const [openResetLeaveDialog, setOpenResetLeaveDialog] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [openResetUsedLeavesConfirmationBox, setOpenResetUsedLeavesConfirmationBox] = useState(false);
    const [resetleaveValue, setResetLeaveValue] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);

    const handleChangeFilterRows = (event) => {
        props.setSearchString(event.target.value);
    };


    const handleAddUser = () => {
        navigate("/userinfo/user?adduser=true")
    }

    const handleEdit = () => {
        console.log(selected)
        const userId = 1; //want to get from props, 
        //want to extract from selected
        navigate("/Userinfo/user?mode=edit&userId=" + selected[0])
    }

    const handleView = () => {
        const userId = 1; //want to get from props, 
        //want to extract from selected
        navigate("/Userinfo/user?mode=view&userId=" + selected[0])
    }

    const handleResetLeaves = () => {
        setOpenResetLeaveDialog(true);
    }

    const handleOpenResetDialog = () => {
        setOpenResetLeaveDialog(false);
    }

    const handleSubmitResetDialog = () => {
        let newRows = []
        console.log(rows)
        for(let i =0 ; i<selected.length; i++){
            rows.map((row,index) => {
                if(row.id == selected[i]){
                    newRows.push(row.name)
                }
            })
            
        }
        setSelectedRows(newRows);
        setOpenResetLeaveDialog(false);
        setOpenConfirmationBox(true);
    }

    const handleCloseConfirmationBox = () => {
        setOpenConfirmationBox(false);
        setOpenResetUsedLeavesConfirmationBox(false);
    }

    const submitResetLeave = () => {
        if (selected.length > 1) {
            axios.post(env.BASE_URL + "/user/resetleave", {
                leaveHours : resetleaveValue,
                userIds : selected
            },{withCredentials: true})
                .then(response => {
                    alert(response.statusText);
                })
                .catch(function (error) {
                    alert(error)
                }) 
        }
        else {
            axios.post(env.BASE_URL + "/user/" + selected[0] + "/resetleave/" + resetleaveValue, {},{withCredentials: true})
                .then(response => {
                    alert(response.statusText);
                })
                .catch(function (error) {
                    alert(error)
                })
        }
        setOpenConfirmationBox(false);
        setSelected([]);
        setResetLeaveValue("");
        setUpdate((update) => !update);
    }

    const handleResetUsedLeaves = () => {
        let newRows = []
        console.log(rows)
        for(let i =0 ; i<selected.length; i++){
            rows.map((row,index) => {
                if(row.id == selected[i]){
                    newRows.push(row.name)
                }
            })
            
        }
        setSelectedRows(newRows);
        setOpenResetUsedLeavesConfirmationBox(true);
    }

    const submitResetUsedLeaves = () => {
        axios.post(env.BASE_URL + "/user/resetusedleave", {
            userIds : selected
        },{withCredentials: true})
            .then(response => {
                console.log(response)
                alert(response.data);
            })
            .catch(function (error) {
                alert(error)
            }) 
        setOpenResetUsedLeavesConfirmationBox(false);
        setSelected([]);
        setUpdate((update) => !update);
    }

    return (
        <>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Employee Table
                    </Typography>
                )}

                {(numSelected == 1) ? (
                    <>

                        <Tooltip title="View">
                            <IconButton onClick={handleView}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Edit">
                            <IconButton onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Reset Granted Leaves">
                            <IconButton onClick={handleResetLeaves}>
                                <SyncIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <IconButton>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Reset Used Leaves">
                            <IconButton onClick={handleResetUsedLeaves}>
                                <LockResetIcon />
                            </IconButton>
                        </Tooltip>

                    </>
                ) : ((numSelected > 1) ? (
                    <>
                        <Tooltip title="Reset Granted Leaves For All User">
                            <IconButton onClick={handleResetLeaves}>
                                <SyncIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Reset Used Leaves">
                            <IconButton onClick={handleResetUsedLeaves}>
                                <LockResetIcon />
                            </IconButton>
                        </Tooltip>
                    </>

                ) : (
                    <>
                        <Tooltip title="Add">
                            <IconButton onClick={handleAddUser}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>

                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={handleChangeFilterRows}
                            />
                        </Search>
                    </>
                )
                )}
            </Toolbar>
            <Dialog
                open={openResetLeaveDialog}
                onClose={handleOpenResetDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ minWidth: '900', minHeight: '450' }}
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
                    Reset Granted Leaves
                </DialogTitle>

                <DialogContent>
                    <TextField
                        id="resetleave"
                        name="resetleave"
                        label="Reset Leave"
                        value={resetleaveValue}
                        fullWidth
                        variant='standard'
                        type="number"
                        autoFocus
                        onChange={(e) => setResetLeaveValue(e.target.value)}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleOpenResetDialog}>Cancel</Button>
                    <Button onClick={handleSubmitResetDialog}>Reset</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmationBox}
                onClose={handleCloseConfirmationBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ minWidth: '900', minHeight: '450' }}
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
                   Confirm Reset Granted Leaves : <strong> {resetleaveValue} </strong> hour(s)
                </DialogTitle>

                <DialogContent>
                    <h1> Employee List </h1>
                    {selectedRows.map((row,index) => {
                        return <h3 key={index}> {row} </h3>
                    })}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseConfirmationBox}>Cancel</Button>
                    <Button onClick={submitResetLeave}>OK</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openResetUsedLeavesConfirmationBox}
                onClose={handleCloseConfirmationBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ minWidth: '900', minHeight: '450' }}
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#006DCC ', color: 'white' }}>
                   <strong> RESET ALL THE USED LEAVES</strong>
                </DialogTitle>

                <DialogContent>
                    <h1> Employee List </h1>
                    {selectedRows.map((row,index) => {
                        return <h3 key={index}> {row} </h3>
                    })}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseConfirmationBox}>Cancel</Button>
                    <Button onClick={submitResetUsedLeaves}>OK</Button>
                </DialogActions>
            </Dialog>
        </>

    );
}

UserTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


export default UserTableToolbar