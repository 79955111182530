import React, { useState, useEffect } from 'react'
import FormikControl from '../../components/formikComponents/FormikControl'
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import GridItem from '../../components/Grid/GridItem';
import axios from 'axios';
import env from "../../services/ApiServices";

function ManagerialRoles(props) {
  const [users, setUsers] = useState([]);
  const [managerList, setManagerList] = useState([])
  const { isViewMode } = props

  useEffect(() => {
    axios.get(env.BASE_URL + "/user/all",{withCredentials: true})
      .then(response => {
        setUsers(response.data)
      });
  }, [])

  useEffect(() => {
    var managers = [{
      key: "select supervisor",
      value: -1
    }]
    if (users.length != 0) {
      users.map((user, index) => {
        if (user.isAdmin || user.isManager) {
          const newManager = {
            key: user.firstName + " " + user.lastName,
            value: user.userId
          }
          managers.push(newManager);
        }
      })
      setManagerList(managers)
    }
  }, [users])


  // setManagerList([
  //   { key: 'Supervisor name 1', value: 'supervisor1Id' },
  //   { key: 'Supervisor name 2', value: 'supervisor2Id' }
  // ])


  return (
    <CardContent style={{
      backgroundColor : 'rgb(5%, 75%, 92%)',
      borderRadius : '10px',
      marginBottom:'10px'
    }}>
      <Typography gutterBottom variant="h5" component="div">
        Job role
      </Typography>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='input'
          label='Title'
          name='title'
          disabled={isViewMode}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='checkBox'
          type='checkBox'
          label='Admin'
          name='isAdmin'
          disabled={isViewMode}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='checkBox'
          type='checkBox'
          label='Manger / Supervisor'
          name='isManager'
          disabled={isViewMode}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='select'
          label='Select the supervisor'
          name='supervisorId'
          disabled={isViewMode}
          options={managerList}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>
    </CardContent>
  )
}

export default ManagerialRoles