import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/formikComponents/FormikControl'
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import GridItem from '../../components/Grid/GridItem';

function ContactDetails(props) {

  const { isViewMode } = props


  return (

    <CardContent style={{
      backgroundColor : 'rgb(5%, 75%, 88%)',
      borderRadius : '10px',
      marginBottom:'10px'
    }}>
      <Typography gutterBottom variant="h5" component="div">
        Contact Details
      </Typography>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='input'
          type='text'
          label='Contact number 1'
          name='contactNumberOne'
          disabled={isViewMode}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='input'
          type='text'
          label='Contact number 2'
          name='contactNumberTwo'
          disabled={isViewMode}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormikControl
          control='input'
          type='text'
          label='emergency contact number'
          name='emergencyContactNumber'
          disabled={isViewMode}
          formControlProps={{
            fullWidth: true
          }}
        />
      </GridItem>
    </CardContent>

  )
}

export default ContactDetails
