import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/formikComponents/FormikControl'
import { FileInput } from "formik-file-and-image-input/lib"
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import GridItem from '../../components/Grid/GridItem'

function CustomFileInputWrapper({ onClick, fileName }) {
  return (
    <div>
      <button onClick={onClick}>Choose File</button>
      <p>{fileName}</p>
    </div>
  )
}



function EducationQualification(props) {
  const { isViewMode } = props

  const fileFormats = ["application/pdf"];
  const initialValues = {

    description: '',
    file: null

  }
  const validationSchema = Yup.object({

    name: Yup.string().required('Required'),
    file: Yup.mixed().required(),
  })
  const onSubmit = values => {
    console.log('Form data', values)
    console.log('Saved data', JSON.parse(JSON.stringify(values)))
  }

  return (
    // <Card sx={{ maxWidth: 345 }}>
    <CardContent style={{
      backgroundColor : 'rgb(5%, 75%, 90%)',
      borderRadius : '10px',
      marginBottom:'10px'
    }}>
      <Typography gutterBottom variant="h5" component="div">
        Education qualification
      </Typography>

      <GridItem xs={12} sm={12} md={6}>
      <FormikControl
        control='textarea'
        label='Description '
        name=' description'
        disabled={isViewMode}
        formControlProps={{
          fullWidth: true
        }}
      />
      </GridItem>
      {/* <FileInput
        name="file"
        validFormats={fileFormats}
        component={CustomFileInputWrapper}
      /> */}

    </CardContent>

  )
}
export default EducationQualification