import * as React from 'react';


import { Box, Grid, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';



import TimeSheetDialog from './TimeSheetDialog';
import axios from 'axios';
import UserTableHeader from '../../components/feature/user/UserTableHeader';
import UserTableToolbar from '../../components/feature/user/UserTableToolbar';
import NavBar from '../navigationBar/NavBar';
import env from "../../services/ApiServices";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: 'red',
  },
}));



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}





export default function UserLeaveTable(props) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [searchString, setSearchString] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [filterRows, setFilterRows] = React.useState(rows);
  const [openDialog, setOpenDialog] = React.useState(true);
  const [currentUser, setCurrentUser] = React.useState([]);
  /**
 * Fetching data through the api request and setting 
 * the values to the rows hook which is supposed to
 * contain all the users information
 */
  React.useEffect(() => {
    axios.get(env.BASE_URL + "/user/getleaves", {
      withCredentials: true
    },
    ).then(response => {
      setRows(() => response.data)
    });
  }, [update]);

  React.useEffect(() => {
    axios.get(env.BASE_URL + "/user", {withCredentials: true})
      .then(response => {
        setCurrentUser(response.data);
      })
  }, [])

  function searchStringFunction(arr, search) {
    const res = arr.filter(obj => Object.values(obj).some(val => val.toString().toLowerCase().includes(search.toLowerCase())
      // console.log("val",val)
    ));
    return res;
  }
  React.useEffect(() => {
    const searchval = searchStringFunction(rows, searchString);
    setFilterRows(searchval);
  }, [searchString, rows]);



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleRowClick = (event, id) => {

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;


  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



  return (
    <>
      <NavBar currentUser={currentUser}/>
      <Paper sx={{ justifyContent: 'center', alignItems: 'center', mt:5 }}>

        <Grid container xs={12} justifyContent='center' alignItems='center' p={2} >

          <Typography component="h1" variant="h5" style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }} >Employee List</Typography>

          <Grid container xs={12} justifyContent='center' alignItems='center' p={2} >

            <Paper sx={{ justifyContent: 'center', alignItems: 'center' }}>

              <UserTableToolbar selected = {selected} setSelected={setSelected} rows = {rows} setSearchString={setSearchString} setUpdate={setUpdate} />

              <TableContainer >

                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >

                  <UserTableHeader
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>

                    {stableSort(filterRows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleRowClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                onClick={(event) => handleClick(event, row.id)}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"

                            >
                              {row.name}
                            </TableCell>

                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              style={{}}
                            >
                              <Grid
                                container
                                xs={6}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <TimeSheetDialog userId={row.id} />
                              </Grid>
                            </TableCell>
                            <TableCell style={{ width: 100 }} align="right">
                              {" "}
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container xs={12}>
                                  <Grid
                                    container
                                    xs={6}
                                    justifyContent="start"
                                    alignItems="start"
                                  >
                                    {row.leaves}
                                  </Grid>

                                  <Grid
                                    container
                                    xs={6}
                                    justifyContent="end"
                                    alignItems="end"
                                  >
                                    {row.maxLeaves - row.leaves}
                                  </Grid>
                                </Grid>
                                <Tooltip
                                  title={
                                    "Remaining Leaves" +
                                    " " +
                                    (row.maxLeaves - row.leaves) +
                                    "/" +
                                    row.maxLeaves
                                  }
                                >
                                  <BorderLinearProgress
                                    variant="determinate"
                                    sx={{ backgroundColor: "green" }}
                                    value={(row.leaves / row.maxLeaves) * 100}
                                  />
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell>{row.lastSubmittedDate}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>



        </Grid>
      </Paper>
    </>
  );
}